<template>
  <div style="text-align: left">

    <article>
      <section>
        <div class="safari_dialogs">
          <el-dialog
            :title="`Edit Experience`"
            :visible.sync="isEditPackageDialogVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <!-- {{ safariPackage }} -->
            <div v-loading="isUploadingFile">
              <el-form
                :model="destinationForm"
                :rules="packageRules"
                ref="destinationForm"
                class="demo-destinationForm2"
              >
                <div class="row">
                  <div class="col-md-12">
                    <label>Title</label>
                    <el-form-item prop="title">
                      <el-input v-model="destinationForm.title"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >

                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Description</label><br />
                    <el-form-item prop="description">
                      <el-input
                        type="textarea"
                        v-model="destinationForm.description"
                        :rows="2"
                        placeholder="please input here"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="editSafariPackage('destinationForm')"
                  >Edit Experience</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2023 Ihamba Adventures | All rights reserved.</small>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
export default {
  data() {
    return {
      addPackageModal: false,
      selectedFile: null,
      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      destinationForm: {
        title: "",
        amount: "",
        destination_country: "",
        location: "",
        safari_type: "",
        description: "",
        sub_destination_id: 1,
      },
      fileLists: [],

      countries: [
        {
          id: "1",
          name: "Uganda",
          value: "Uganda",
        },
        {
          id: "2",
          name: "Kenya",
          value: "Kenya",
        },
        {
          id: "3",
          name: "Tanzania",
          value: "Tanzania",
        },
        {
          id: "4",
          name: "Rwanda",
          value: "Rwanda",
        },
        {
          id: "5",
          name: "Burundi",
          value: "Burundi",
        },
        {
          id: "6",
          name: "D.R Congo",
          value: "D.R Congo",
        },
        {
          id: "7",
          name: "South Sudan",
          value: "South Sudan",
        },
      ],

      packageRules: {
        title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "Amount is required",
            trigger: "blur",
          },
        ],
        destination_country: [
          {
            required: true,
            message: "destination_country is required",
            trigger: "change",
          },
        ],
        location: [
          {
            required: true,
            message: "location is required",
            trigger: "change",
          },
        ],
        safari_type: [
          {
            required: true,
            message: "safari_type is required",
            trigger: "change",
          },
        ],
        description: [
          {
            required: true,
            message: "description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    isEditPackageDialogVisible: {
      required: true,
      type: Boolean,
    },
    safariPackage: {
      required: true,
      type: Object,
    },
  },

  watch: {
    isEditPackageDialogVisible() {
      if (this.isEditPackageDialogVisible) {
        this.destinationForm.title = this.safariPackage.title;
        this.destinationForm.amount = this.safariPackage.amount;
        this.destinationForm.destination_country =
          this.safariPackage.destination_country;
        this.destinationForm.location = this.safariPackage.location;
        this.destinationForm.safari_type = this.safariPackage.safari_type;
        this.destinationForm.description = this.safariPackage.description;
        this.destinationForm.sub_destination_id = 1;
        this.selectedFile = this.safariPackage.image;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("closeEditPackageDialog");
    },

    handlePreview(file) {
      console.log(file.raw);
      this.fileLists = [file];
      this.selectedFile = file.raw;
    },
    handleRemove() {
      this.selectedFile = null;
    },

    async editSafariPackage(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.$notify({
              title: "Empty",
              message: "Profile picture is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("title", this.destinationForm.title);
            formData.append("image", this.selectedFile);
            formData.append("description", this.destinationForm.description);
            let request = await this.$http.post(
              `api/experience/edit/${this.safariPackage.id}`,
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success &&
              request.data.message == "DESTINATION_EDITED_SUCCESSFULLY"
            ) {
              this.$emit("refreshPackages");
              this.$notify({
                title: "Success",
                message: "Edited successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to edit destination now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showAddPackageModal() {
      this.addPackageModal = true;
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
